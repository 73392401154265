import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js' // import { get, post, put, patch, destroy } from '@rails/request.js'

// Connects to data-controller="assignments"
export default class extends Controller {

	static values = { 
		context: String, // might be 'assignments#manage' or 'users#show'
		url: String
	}

	connect() {
		// AppContext.log( `${this.identifier}_controller#connect` )
		this.key = this.element.dataset.key
	}

	async modify() {
		
		this.getIds()
		this.getQuery()

		let body = {
			context: this.contextValue,
			// key: this.key,
			// ids: this.ids,
			q: this.query
		}
		body[this.key] = this.ids

		const request = this.buildRequest( body )
		const response = await request.perform()
		if (response.ok) {
			this.changed()
		}
	}

	changed() {
		// let the selection controller reset the selection, and therefore trigger the visibility listeners
		// may not be necessary since the rendered results may inject a new selection_controller attached to a new list. 
		// but a future implementation may choose to cherry-pick the page updates for the rows that changed, so need to notify selection controller we are done with the selection
		window.dispatchEvent( new CustomEvent( "selection:processed" ) ) 
	}

	getIds() {
		this.ids = Object.keys( AppContext.selection[this.key] ).map( dom_id => dom_id.split( '_' ).pop() ).join( ',' )
	}
	
	getQuery() {
		this.query = AppContext.query
	}
	
	buildRequest( body ) {
		return new FetchRequest( 
			'post', 
			this.urlValue, 
			{ 
				body: JSON.stringify( body ),
				responseKind: 'turbo-stream'
			} 
		)
	}
	
}

