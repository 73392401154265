import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="selection"
export default class extends Controller {

// toggles are selectors for querySelectorAll to toggle the 'hidden' class
// input (if provided) is a querySelector (best is a #id type selector) for the form field that will get the selected list
// selected is the state that tracks the currently selected items indexed by the data-id attribute value
	static values = {
		multiple: { type: Boolean, default: true },
		toggles: String,
		toggle: String, // the css class to toggle on this element
		// input: String,
		// type: String, // the data-type value
	}
	
	static targets = [ 'heading' ]

	static total_selection_count = 0;

	initialize(){
		this.key = this.element.dataset.key
		AppContext.selection = AppContext.selection || {}
		AppContext.selection[ this.key ] ||= {}
		const over_count = Object.keys( AppContext.selection[ this.key ] ).length
		this.constructor.total_selection_count -= over_count
		AppContext.selection[ this.key ] = {}
	}
	
	connect() {
		// console.log( `${this.identifier}_controller#connect` )

		this.key = this.element.dataset.key
		this.current_toggle_state = this.constructor.total_selection_count > 0 // length( AppContext.selection[ this.key ] ) > 0
		const els = this.element.querySelectorAll( `[data-action].selected` ) || [] // `[data-action="click->selection#select"].selected`
		this.registerSelectionOnConnect( els )
  }
	
	disconnect() {
		// console.log( `${this.identifier}_controller#disconnect (${this.key})` )
		// delete AppContext.selection[ this.key ]
		this.reset()
	}
	
	select( e ) {
		// assumes e is the row/card that has an id value in the form of a dom_id (moden_name_id) e.g. 'User_123'
		this.element.classList.toggle( 'de-emphasize', false )
		const el = e.currentTarget //
		this.toggleSelection( el ) // will send a selection:zero event if it crosses zero
		this.setInputValue() // update the dom element's value
		// this.toggleVisibilities()
	}

	get_count() {
		return this.constructor.total_selection_count
	}

	reset(){
		this.clearSelection()
		this.setInputValue() // clears value in form since internal value (above) is empty
		// this.toggleVisibilities() // handles the element toggles
		this.element.classList.toggle( 'de-emphasize', false )
	}
	
	registerSelectionOnConnect( els ){
		els.forEach( e => this.toggleSelection( e ) )
		this.setInputValue()
		// this.toggleVisibilities()
	}
	
	clearSelection() {
		let selected_count = Object.keys( AppContext.selection[ this.key ] ).length
		Object.values( AppContext.selection[ this.key ] ).forEach( element => { // (element, index, array) => {
			element.classList.toggle( 'selected', false )
		} )
		this.constructor.total_selection_count -= selected_count

		AppContext.selection[ this.key ] = {} // clear our internal value
		window.dispatchEvent( new CustomEvent( "selection:zero", { detail: { count: 0, total: this.constructor.total_selection_count, key: this.key, element: this } } ) )
		if ( this.hasHeadingTarget && this.hasToggleValue ) {
			this.headingTarget.classList.toggle( this.toggleValue, false )
		}
	}
	addSelection( el ) {
		AppContext.selection[ this.key ][ el.id ] = el
		el.classList.toggle( 'selected', true )
		this.constructor.total_selection_count += 1
	}
	removeSelection( el ) {
		delete AppContext.selection[ this.key ][ el.id ]
		el.classList.toggle( 'selected', false )
		this.constructor.total_selection_count -= 1
	}
	toggleSelection( el ) {
		const selected = el.classList.contains('selected')
		// IF WE ARE CROSSING FROM ZERO, SEND SIGNAL TO OTHER SELECTION CONTROLLERS TO CLEAR
		const from_zero = Object.keys( AppContext.selection[ this.key ] ).length == 0
		if ( this.multipleValue ) {
			if ( selected ) {
				// already selected, so we will be removing the selection
				// we may or may not end with anything selected
				this.removeSelection( el )
			}
			else {
				this.addSelection( el )
			}
		}
		else {
			// only single selections allowed
			if ( selected ) {
				// already selected, so we will be removing the selection
				// we will end with nothing selected
				this.removeSelection( el )
			}
			else {
				// we may need to remove the selection class from the previous selection
				this.clearSelection()
				this.addSelection( el )
			}
		}
		if ( from_zero ) {
			// tell other selection_controller instances to clear their list
			window.dispatchEvent( new CustomEvent( "selection:zero", { detail: { count: 1, total: this.constructor.total_selection_count, key: this.key, element: this } } ) )
			// console.log('dispatching selection:zero')
			if ( this.hasHeadingTarget && this.hasToggleValue ) {
				this.headingTarget.classList.toggle( this.toggleValue, true )
			}
		}
		const to_zero = Object.keys( AppContext.selection[ this.key ] ).length == 0
		if ( to_zero ) {
			// tell other selection_controller instances to clear their list
			window.dispatchEvent( new CustomEvent( "selection:zero", { detail: { count: 0, total: this.constructor.total_selection_count, key: this.key, element: this } } ) )
			if ( this.hasHeadingTarget && this.hasToggleValue ) {
				this.headingTarget.classList.toggle( this.toggleValue, false )
			}
		}
	}

	engage( { detail: { count, element } } ) {
		if ( element != this ) {
			if ( count > 0 ) {
				this.clearSelection()
				this.element.classList.toggle( 'de-emphasize', true )
			} 
			else {
				this.element.classList.toggle( 'de-emphasize', false )
			}

		}
	}

	setInputValue(){
		if ( !!this.key ) {
			this.ids = dom_ids_to_ids_string( Object.keys( AppContext.selection[ this.key ] ) )
			const input = document.getElementById( this.key )
			if ( input ) {
				input.value = this.ids
			}
		}
	}

	toggleVisibilities(){
		window.dispatchEvent( new CustomEvent( "selection:changed", { detail: { count: this.constructor.total_selection_count, key: this.key, element: this } } ) )
	}
	
}

function dom_ids_to_ids_string( arr ){
	return arr.map( dom_id => dom_id.split( '_' ).pop() ).join( ',' );
}

function length( obj ) {
	return Object.keys( obj ).length
}
