import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {

	static targets = [ "item" ]

	query( { detail: { content } } ) {
		// AppContext.log( `filter#query received "${content}"` ) // query string
		let tokens = content.toLowerCase().split( /\s+/ ).filter( token => token )
		this.itemTargets.forEach( element => {
			let text = element.innerText.toLowerCase()
			let match = true
			tokens.forEach( token => match && ( match = text.indexOf( token ) >= 0 ) )
			const hide = !(match || element.classList.contains('selected'))
			element.classList.toggle( 'hidden', hide )
		})
	}
}
