// Toggles the visibility of its element based on the event's count parameter
// if a key was specified, it only toggle visibility if it is the same key as the one specified in the event details

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="visibility"
export default class extends Controller {

	connect() {
		this.normally_hidden = this.element.dataset.visibility == 'hidden'
		this.element.classList.toggle( 'hidden', ( this.normally_hidden ) )
	}

	toggle( { detail: { count, total, key, element } } ) {
		// console.log(`count='${count}', key=${key}`)
		let data = this.element.dataset
		let controller_key = data.key
		if ( !! count ) { // false if count == 0 or true if count != 0
			if ( controller_key ) {
				if ( controller_key == key ) {
					this.element.classList.toggle( 'hidden', ! ( this.normally_hidden ) )
				}
				else {
					// this.element.classList.toggle( 'hidden', ( this.normally_hidden ) )
				}
			}
			else {
				this.element.classList.toggle( 'hidden', ! ( this.normally_hidden ) )
			}
		}
		else {
			// this.element.classList.toggle( 'hidden', ( this.normally_hidden ) )
			if ( controller_key ) {
				if ( controller_key == key ) {
					this.element.classList.toggle( 'hidden', ( this.normally_hidden ) )
				}
				else {
					// this.element.classList.toggle( 'hidden', ! ( this.normally_hidden ) )
				}
			}
			else {
				if ( total == 0 ) {
					this.element.classList.toggle( 'hidden', ( this.normally_hidden ) )
				}
			}
		}
	}

}


