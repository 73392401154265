import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"
import { get } from "@rails/request.js"
// import { FetchRequest } from '@rails/request.js' // import { get, post, put, patch, destroy } from '@rails/request.js'

// Connects to data-controller="load-more"
export default class extends Controller {
	static values = {
		listId: String,
		url: String,
		includeQuery: Boolean
	}

	connect() {
		// AppContext.log( `${this.identifier}_controller#connect and ${this.includeQueryValue ? 'WITH' : 'WITHOUT'} query` )
		this.page = 1
		this.fetchingData = false
		useIntersection(this)
	}

	async appear () {
		if (this.fetchingData || this.hasNoMoreResultsItem) return
		this.fetchingData = true
		this.page = this.page + 1
		// console.log("load-more", this.page, this.listIdValue)

		let query = {
			page: this.page,
			turbo_target: this.listIdValue
		}
		if ( this.includeQueryValue ) {
			this.getQuery() // in case we are currently filtering the results on the page
			if ( !! this.query ) {
				query[ 'q' ] = this.query
			}
			// console.log(`including query: '${this.query}'`)
		}
		else {
			// console.log(`NOT including query: '${this.query}'`)
		}



		await get( this.urlValue, {
			query: query,
			responseKind: "turbo-stream"
		})

		// let body = {
		// 	page: this.page,
		// 	turbo_target: this.listIdValue
		// }

		// const request = this.buildRequest( body )
		// const response = await request.perform()
		// if (response.ok) {
		// 	// alert(response)
		// }


		this.fetchingData = false
	}

	get hasNoMoreResultsItem () {
		return document.getElementById(this.listIdValue).querySelector(`[data-no-more-results]`) != null
	}

	getQuery() {
		this.query = AppContext.query
	}

	// buildRequest( body ) {
	// 	console.log("load-more", this.urlValue)
	// 	return new FetchRequest(
	// 		'get',
	// 		this.urlValue,
	// 		{
	// 			query: body,
	// 			responseKind: 'turbo-stream'
	// 		}
	// 	)
	// }

}