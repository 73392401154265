import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

// Connects to data-controller="transfer"
export default class extends Controller {

	static values = { 
		url: String, // the url to submit the request
		context: String, // (accounts|designations|users) which should affect the template used to render the results.
		// input: String, // used both as the id for the form input (if it exists) and as the key for AppContext.selection[key]
		// key: String, // used both as the id for the form input (if it exists) and as the key for AppContext.selection[key]
		designationId: String // should be integer (Number), but defaults to 0, whereas String defaults to empty string
	}
	static targets = [ 'sponsorship' ]

	connect() {
		this.key = this.element.dataset.key
		// AppContext.log( `${this.identifier}_controller#connect` )
		const event = new CustomEvent( "modal:show" )
		window.dispatchEvent(event)
		// document.querySelector( this.inputValue ).value = this.getIds()
		document.getElementById( this.key ).value = this.getIds()
	}

	async submit( e ) { // meant for transfer action
		const button = e.currentTarget
		const mode = button.dataset.mode
		
		this.getIds()

		let body = {
			context: this.contextValue,
			// designation_ids: this.ids,
			account_id: this.sponsorshipTarget.value,
			mode: mode 
		}
		body[ this.key ] = this.ids

		const request = this.buildRequest( body )
		const response = await request.perform()
		if (response.ok) {
			const body = await response.text
			if ( ! body.match(/data-error/) ) {
				this.changed()
			}
		}

	}

	changed() {
		this.sponsorshipTarget.value = null
		window.dispatchEvent( new CustomEvent( "selection:processed" ) )
		window.dispatchEvent( new CustomEvent( "modal:hide" ) )
	}

	getIds() {
		if ( this.hasDesignationIdValue ) {
			this.ids = this.designationIdValue // expects a comma-separated string, not an array
		}
		else {
			// this.ids = Object.keys( AppContext.selection[this.inputValue] ).map( dom_id => dom_id.split( '_' ).pop() ).join( ',' )
			this.ids = Object.keys( AppContext.selection[this.key] ).map( dom_id => dom_id.split( '_' ).pop() ).join( ',' )
		}
		return this.ids
	}
	
	buildRequest( body ) {
		return new FetchRequest( 
			'post', 
			this.urlValue, 
			{ 
				body: JSON.stringify( body ),
				responseKind: 'turbo-stream'
			} 
		)
	}
}
