// Checks or Unchecks the checkboxes carrying the '.checklist-box' selector that are child elements within visible '[data-filter-target=item]' elements
// Assumes the selectors for both the containing item as well as the checkbox, both of which may be extracted and supplied via static values

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checklist"
export default class extends Controller {
	// checklist_selector_value: "[name='billing[challenge_ids][]']"
	static values = { 
		selector: {type: String, default: '[data-filter-target=item]:not(.hidden) input[type=checkbox].checklist-box'}
	}

  connect() {
		// AppContext.log( `${this.identifier}_controller#connect` )
		// AppContext.log( `using new selectorValue:"${this.selectorValue}"` )
  }
	
	checkVisible( event ){
		event.preventDefault(); // either way
		setCheckedProperty( true, this.selectorValue )
	}

	uncheckVisible( event ){
		event.preventDefault(); // either way
		setCheckedProperty( false, this.selectorValue )
	}

}

function setCheckedProperty( checked, selector ) {
	// const visibleCheckboxes = document.querySelectorAll('[data-filter-target=item]:not(.hidden) input[type=checkbox].checklist-box')
	// AppContext.log(this.selectorValue)
	const visibleCheckboxes = document.querySelectorAll( selector )
	// console.log( visibleCheckboxes )
	for (let box of visibleCheckboxes ) {
		box.checked = checked;
	}
}
