export default class AppContext {
	constructor() {
		// defaults to true
		this.debug = false // true
	}
	log( msg ) {
		if ( this.debug ) {
			console.log( msg )
		}
	}
	toggleMenu( action = null ){
		let menu = document.getElementById( "menu" )
		if ( menu ) {
			let menuClassList = menu.classList
			if ( action == 'open' ) {
				menuClassList.add( 'active')
			}
			else {
				if ( action == 'close' ) {
					menuClassList.remove( 'active' )
				}
				else {
					menuClassList.toggle( 'active' )
				}
			}
		}
	}
}
// AppContext.debug = true // false

/**
 * Element.closest() polyfill
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
 */
if ( !Element.prototype.matches ) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this
		do {
			if (Element.prototype.matches.call(el, s)) {
				return el
			}
			el = el.parentElement || el.parentNode
		} while (el !== null && el.nodeType === 1)
		return null
	}
}

/*
 * Date.toISODateString()
 // .toISOString().substring(0,10)
*/
if (!Date.prototype.toISODateString) {
  (function() {
    function pad(number) {
      if (number < 10) {
        return '0' + number;
      }
      return number;
    }
    Date.prototype.toISODateString = function() {
      return this.getUTCFullYear() + '-' + pad(this.getUTCMonth() + 1) + '-' + pad(this.getUTCDate());
    };
  })();
}
if (!Date.prototype.shiftMonths) {
  (function() {
    Date.prototype.shiftMonths = function( months ) {
			const month = this.getUTCMonth() + months % 12;
			const year = this.getUTCFullYear() + months / 12;
      return new Date( year, month, this.getUTCDate() );
    };
  })();
}

// AppContext.log('AppContext initialized and polyfills defined in app_context.js')
// export { AppContext }