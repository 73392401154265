import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js' // import { get, post, put, patch, destroy } from '@rails/request.js'

// Connects to data-controller="sponsorship"
export default class extends Controller {

	static values = { 
		url: String,
		context: { type: String, default: 'accounts' }, // String,
		// input: String, // used both as the id for the form input (if it exists) and as the key for AppContext.selection[key]
		memberId: String,
		designationId: String // should be integer (Number), but defaults to 0, whereas String defaults to empty string
	}

	connect() {
		this.key = this.element.dataset.key
		// AppContext.log( `${this.identifier}_controller#connect` )
	}

	async status( e ) {

		const button = e.currentTarget
		const mode = button.dataset.mode // expecting one of ["revoke", "unassign", "restore"]
		
		this.getIds()

		let body = {
			context: this.contextValue,
			designation_ids: this.ids,
			member: this.memberIdValue, 
			mode: mode 
		}

		const request = this.buildRequest( body )
		const response = await request.perform()
		if (response.ok) {
			this.changed()
		}
	}

	changed() {
		window.dispatchEvent( new CustomEvent( "selection:processed" ) )
	}

	getIds() {
		if ( this.hasDesignationIdValue ) {
			this.ids = this.designationIdValue // expects a comma-separated string, not an array
		}
		else {
			// read off of [ :data ][ :key ] element attribute
			this.ids = Object.keys( AppContext.selection[this.key] ).map( dom_id => dom_id.split( '_' ).pop() ).join( ',' )
		}
	}
	
	buildRequest( body ) {
		return new FetchRequest( 
			'post', 
			this.urlValue, 
			{ 
				body: JSON.stringify( body ),
				responseKind: 'turbo-stream'
			} 
		)
	}
	
}

