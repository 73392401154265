import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dispatch"
export default class extends Controller {

	// static values = {
	// 	key:  String // the name of the CustomEvent to dispatch
	// }
	//
  connect() {
		this.key = this.element.dataset.event
		// AppContext.log(`dispatch_controller#connect with '${this.key}'`)
  }
	
	event() {
		// AppContext.log(`dispatch_controller#event using '${this.key}'`)
		window.dispatchEvent( new CustomEvent( this.key, { detail: { element: this } } ) )
	}
}
