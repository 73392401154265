import { Controller } from "@hotwired/stimulus"
import { cancel, resetInput } from "../helpers/element_helpers"

// Connects to data-controller="query"
export default class extends Controller {

	connect() {
		this.element.focus()
	}

	changed() {
		window.dispatchEvent( new CustomEvent( "query:changed", { detail: { content: this.element.value } } ) )
	}

	resetByKeyboard({ key, target }) {
		if (key == "Escape") {
			target.value ? resetInput(target) : target.form?.reset()
		}
	}

}
// keydown->query#resetByKeyboard