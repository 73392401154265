import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll"
export default class extends Controller {
  connect() {
		const body = this.element
		this.styleAttribute = body.style
		this.overflowStyle = body.style.overflow
		this.paddingRightStyle = body.style.paddingRight
  }
  lock() {
		const body = this.element
		body.style.overflow = 'hidden'
		body.style.paddingRight = '0px'
  }
  unlock() {
		const body = this.element
		body.style.overflow = this.overflowStyle
		body.style.paddingRight = this.paddingRightStyle
		if ( this.styleAttribute.length == 0 ) {
			body.removeAttribute('style')
		}
  }
}
