import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="redirect"
export default class extends Controller {

  connect() {
    this.element.addEventListener("turbo:submit-end", (event) => {
      this.next(event)
    })
		// this.addEventListener("turbo:submit-start", ({ target }) => {
		this.element.addEventListener("turbo:submit-start", ({ target }) => {
		  for (const field of target.elements) {
		    field.disabled = true
		  }
		})
  }

  next(event) {
    if (event.detail.success) {
      Turbo.visit(event.detail.fetchResponse.response.url)
    }
  }

}