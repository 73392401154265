import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'


// Connects to data-controller="modal"
export default class extends Controller {

	static values = { 
		open: String
	}

  connect() {
		useClickOutside( this ) 
		if ( !this.is_open ) {
			this.open()
			// AppContext.log('modal connect (open)')
		}
		else {
			// AppContext.log('modal connect (not open)')
		}
  }

  disconnect() {
		if ( this.is_open ) {
			// AppContext.log('modal disconnect (open)')
			this.close()
		}
		else {
			// AppContext.log('modal disconnect (not open)')
		}
  }

	open(){
		this.is_open = true
		this.originalParentElement = this.element.parentElement
		// AppContext.log('modal#open')
		window.dispatchEvent( new CustomEvent( "modal:show" ) )
		this.element.parentElement.setAttribute('open', 'open')
	}

	close(){
		this.is_open = false
		// AppContext.log('modal#close')
		window.dispatchEvent( new CustomEvent( "modal:hide" ) )
		this.originalParentElement.removeAttribute('open')
		this.originalParentElement.removeAttribute('src')
		this.openValue = ''
		this.element.remove() // .parentElement.removeChild( this.element )
	}
	
	clickOutside(event) {
		event.preventDefault()
		this.close()
	}
}
