import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import { debounce } from "../helpers/debounce_helpers"
import { cancel, resetInput } from "../helpers/element_helpers"

// Connects to data-controller="search"
export default class extends Controller {
	static targets = ['input']

	static values = { 
		url: String, // the url to submit the request
	}

	connect() {
		// AppContext.log( `${this.identifier}_controller#connect` )
		AppContext.query = sanitizeQuery( this.inputTarget.value )
		this.url = this.hasUrlValue ? this.urlValue : this.element.action
		this.method = this.element.method || 'get'
		// AppContext.log( `${this.identifier}_controller url: "${this.url}", method: "${this.method}"` ) // query string
	}
	// TODO: implement search#changed, the input field's input action

	initialize() {
		this.changed = debounce(this.changed.bind(this), 500) // changed was debouncedSubmit
		this.hasInputTarget && this.inputTarget.focus()
	}

	disconnect() {
		this.element.toggleAttribute("data-submitting", false)
	}

	async submit() { // event ) { // meant for transfer action
		// let query = event.currentTarget.value.toLowerCase().replace( /[\\\+\&\$\%\?\;\' ]+/g, ' ' ).replace( /\ *[\,\|\t\n\r]+\ */g, ',' ) // sanitizeQuery( event.currentTarget.value )
		let query = sanitizeQuery( this.inputTarget.value )
		// this.inputTarget.value.toLowerCase().replace( /^[\\\+\&\$\%\?\;\' ,\|\t\n\r]+|[\\\+\&\$\%\?\;\' ,\|\t\n\r]+$/g, '' ).replace( /[\\\+\&\$\%\?\;\' ]+/g, ' ' ).replace( /\ *[\,\|\t\n\r]+\ */g, ', ' ) // sanitizeQuery( event.currentTarget.value )
		// AppContext.log( `${this.identifier}_controller#submit{ q: '${query}', url: '${this.url}', method: '${this.method}' }` )
		const request = this.buildGetRequest( query )
		AppContext.query = query // leave a breadcrumb for the other page elements to lookup
		const response = await request.perform()
	}

	buildGetRequest( query ) {
		return new FetchRequest( 
			this.method, 
			this.url,
			{
				responseKind: 'turbo-stream',
				query: { q: query }
			}
		)
	}

	digest( event ) {
		event.preventDefault()
		let paste = ( event.clipboardData || window.clipboardData ).getData( 'text' )
		// let query = paste.toLowerCase().replace( /[\\\+\&\$\%\?\;\' ]+/g, ' ' ).replace( /\ *[\,\|\t\n\r]+\ */g, ',' )
		let query = paste.replace( /^[\\\+\&\$\%\?\;\' ,\|\t\n\r]+|[\\\+\&\$\%\?\;\' ,\|\t\n\r]+$/g, '' ).replace( /[\\\+\&\$\%\?\;\' ]+/g, ' ' ).replace( /\ *[\,\|\t\n\r]+\ */g, ', ' )
		this.inputTarget.value = query
		this.changed()
		// AppContext.log( 'paste event' )
	}

	changed(event) { // was debouncedSubmit
		// AppContext.log( `${this.identifier}_controller#changed` )
		this.submit() // event)
	}

	resetByKeyboard({ key, target }) {
		if (key == "Escape") {
			target.value ? resetInput(target) : target.form?.reset()
		}
	}

  suppressValidationMessage(event) {
    cancel(event)
  }

}

function sanitizeQuery( q ) {
	return q.toLowerCase().replace( /^[\\\+\&\$\%\?\;\' ,\|\t\n\r]+|[\\\+\&\$\%\?\;\' ,\|\t\n\r]+$/g, '' ).replace( /[\\\+\&\$\%\?\;\' ]+/g, ' ' ).replace( /\ *[\,\|\t\n\r]+\ */g, ', ' )
}
