// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import app_context from "./app_context/app_context.js"

AppContext = new app_context
// AppContext.log('app/javascript/application.js via window.AppContext HERE')

import bootstrap from "bootstrap/dist/js/bootstrap.bundle"

window.bootstrap = bootstrap

import "./controllers"

// new bootstrap.Popover(document.querySelector('[data-bs-toggle="popover"]'), {
// 	trigger: 'hover'
// })
